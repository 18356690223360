import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { RichText, Link } from "prismic-reactjs"

import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"

const TeamPage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "team",
        page_path: "/team",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)
  const data = useStaticQuery(graphql`
    query {
      prismicTeam {
        data {
          header {
            richText
          }
          introduction_text {
            richText
          }
          body {
            ... on PrismicTeamDataBodyTeamMember {
              primary {
                profile_picture {
                  url
                }
                first_name
                last_name
                description {
                  richText
                }
              }
            }
          }
          collaborators_title {
            richText
          }
          collaborators_text {
            richText
          }
          join_us_title {
            richText
          }
          join_us_text {
            richText
          }
        }
      }
    }
  `)
  const pageData = data.prismicTeam.data
  const teamMembers = pageData.body

  return (
    <>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <div className="w-full" ref={wrapperRef}>
          <div className="w-full px-10 pt-16 pb-12 text-center text-white bg-black md:pb-16">
            <div className="max-w-full text-2xl leading-7 prose text-white">
              <RichText render={pageData.header.richText} />
            </div>
          </div>

          <div className="w-full max-w-full px-8 py-12 mx-auto leading-6 prose text-justify text-black md:px-20 xl:px-32 lg:text-center ">
            <RichText render={pageData.introduction_text.richText} />
          </div>

          {teamMembers.map((member, i) => {
            const memberData = member.primary
            return (
              <div key={i} className="xl:flex xl:mb-10">
                <div className="w-full xl:w-auto xl:flex-1">
                  <img
                    className="object-cover w-full h-auto sm:max-h-315 xl:max-h-full xl:h-full"
                    src={memberData.profile_picture.url}
                  />
                </div>

                <div className="flex flex-col justify-center max-w-full px-8 py-12 prose text-black md:justify-start xl:justify-center md:py-4 md:flex-1">
                  <div className="flex flex-wrap justify-center text-4xl font-semibold leading-10 text-center md:flex-col">
                    <div className="md:pb-2">{memberData.first_name}</div>
                    <div>{memberData.last_name}</div>
                  </div>
                  <div className="leading-6 text-justify lg:text-center md:px-4">
                    <RichText
                      render={memberData.description.richText}
                      elements={{
                        hyperlink: ({ node, children, key }) => {
                          return (
                            <a
                              key={key}
                              className="no-underline"
                              href={children}
                              target="_blank"
                            >
                              {children}
                            </a>
                          )
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className="max-w-full px-8 pt-0 pb-8 prose text-black md:px-12">
            <div className="pb-4 text-4xl font-semibold leading-10 text-center">
              <RichText render={pageData.collaborators_title.richText} />
            </div>
            <div className="leading-6 text-center">
              <RichText render={pageData.collaborators_text.richText} />
            </div>
          </div>

          <div className="max-w-full px-8 pt-0 pb-8 prose text-black md:px-12">
            <div className="pb-4 text-4xl font-semibold leading-10 text-center">
              <RichText render={pageData.join_us_title.richText} />
            </div>
            <div className="leading-6 text-justify lg:text-center">
              <RichText render={pageData.join_us_text.richText} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TeamPage
